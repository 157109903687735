import React, { useState, useEffect, useRef, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { createJob, fetchAssociatedContractors } from '../../api/request';
import { CreateJobRequest, ContractorsResponse, PropertyList } from '../../api/type';
import moment from 'moment';

import cleaningIcon from '../assets/cleaning-dark.svg';
import maintenanceIcon from '../assets/maintenance-dark.svg';
import improvementIcon from '../assets/settings.svg';
import taskIcon from '../assets/file.svg';
import contactIcon from '../assets/phone.svg';
import clockIcon from '../assets/clock.svg';
import xIcon from '../assets/x.svg';
import { ImportantOrgContext } from '../../App';
import homeDark from '../assets/home-dark.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface SidebarProps {
  onClose: () => void;
  sideBar: boolean;
  property: PropertyList | undefined;
  selectedDate: string;
  selectedPage: number;
  onJobCreated: (date: string, newJobId: string | null, pageNumber: number) => void;
}

type JobType = 'Cleaning' | 'Maintenance' | 'Task' | 'Improvement' | 'Contact';

interface Contractor {
  _id: string;
  'POC: Full Name': string | null;
  'DETAILS: Company Name': string;
}


interface ChannelOption {
  value: string;
  label: string;
  icon: string;
}

interface CustomDropdownProps {
  options: { [key: string]: ChannelOption };
  value: string;
  onChange: (value: string) => void;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({ options, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (optionValue: string) => {
    onChange(optionValue);
    setIsOpen(false);
  };

  return (
    <div className="custom-select icon-select">
      <div className="selected-value" onClick={() => setIsOpen(!isOpen)}>
        <img src={options[value].icon} alt={`${options[value].label} icon`} />
        <div>{options[value].label}</div>
      </div>
      {isOpen && (
        <div className="options">
          {Object.keys(options).map((key) => (
            <div
              className="option"
              key={key}
              onClick={() => handleOptionClick(key)}
            >
              <img src={options[key].icon} alt={`${options[key].label} icon`} />
              <div>{options[key].label}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const JobSidebar: React.FC<SidebarProps> = ({ onClose, sideBar, property, selectedDate, selectedPage, onJobCreated }) => {
  const [formData, setFormData] = useState({
    propertyListing: property?.id || '',
    selectedJobType: 'Cleaning' as JobType,
    selectedContractor: '',
    startTime: '',
    endTime: '',
    taskTitle: '',
    taskDescription: '',
    rate: '',
    repeat: false,
    repeatInterval: 1,
    repeatUnit: 'DAILY',
    selectedDays: [] as string[],
    ends: 'never',
    endDate: '',
    occurrences: ''
  });

  const importantOrgId = useContext(ImportantOrgContext);

  const [contractors, setContractors] = useState<Contractor[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [formError, setFormError] = useState<string>('');
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [success, setSuccess] = useState<boolean>(false);
  const errorRef = useRef<HTMLDivElement>(null);
  const successRef = useRef<HTMLDivElement>(null);

  const jobTypeOptions: { [key: string]: ChannelOption } = {
    Cleaning: { label: 'Cleaning', icon: cleaningIcon, value: 'Cleaning' },
    Maintenance: { label: 'Maintenance', icon: maintenanceIcon, value: 'Maintenance' },
    Improvement: { label: 'Improvement', icon: improvementIcon, value: 'Improvement' },
    Task: { label: 'Task', icon: taskIcon, value: 'Task' },
    Contact: { label: 'Contact', icon: contactIcon, value: 'Contact' }
  };

  const daysOfWeek = [
    { full: 'SUNDAY', short: 'Su' },
    { full: 'MONDAY', short: 'Mo' },
    { full: 'TUESDAY', short: 'Tu' },
    { full: 'WEDNESDAY', short: 'We' },
    { full: 'THURSDAY', short: 'Th' },
    { full: 'FRIDAY', short: 'Fr' },
    { full: 'SATURDAY', short: 'Sa' },
  ];

  const getInitials = (name: string) => {
    const names = name.split(' ');
    const initials = names.slice(0, 2).map((n) => n[0]).join('');
    return initials;
  };

  const handleDaySelection = (day: string) => {
    setFormData(prevData => ({
      ...prevData,
      selectedDays: prevData.selectedDays.includes(day)
        ? prevData.selectedDays.filter(d => d !== day)
        : [...prevData.selectedDays, day]
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;

    if (type === 'checkbox') {
      const checked = (e.target as HTMLInputElement).checked;
      setFormData(prevData => ({
        ...prevData,
        [name]: checked
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value
      }));
    }

    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: ''
    }));
  };


  const handleDateChange = (date: Date | null, name: string) => {
    setFormData(prevData => ({
      ...prevData,
      [name]: date ? moment(date).format('YYYY-MM-DD') : ''
    }));

    if (name === 'endDate') {
      setErrors(prevErrors => ({
        ...prevErrors,
        endDate: ''
      }));
    }
  };


  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};

    if (!formData.selectedJobType) newErrors.selectedJobType = 'Job Type is required.';
    if (!formData.selectedContractor) newErrors.selectedContractor = 'Contractor is required.';
    if (!formData.startTime) newErrors.startTime = 'Start time is required.';
    if (!formData.endTime) newErrors.endTime = 'End time is required.';
    if (formData.startTime && formData.endTime && formData.startTime >= formData.endTime) {
      newErrors.endTime = 'End time must be after start time.';
    }
    if (!formData.taskTitle) newErrors.taskTitle = 'Task title is required.';
    if (!formData.rate) newErrors.rate = 'Rate is required.';
    if (parseFloat(formData.rate) < 0) newErrors.rate = 'Rate must be a positive number.';

    if (formData.repeat) {
      if (formData.ends !== 'on' && formData.ends !== 'after') {
        newErrors.ends = 'Please select either "Ends on" or "Ends after".';
      } else {
        if (formData.ends === 'on' && !formData.endDate) {
          newErrors.endDate = 'End date is required.';
        } else if (formData.ends === 'on' && new Date(formData.endDate) <= new Date(selectedDate)) {
          newErrors.endDate = 'End date must be after the start date.';
        }

        if (formData.ends === 'after' && !formData.occurrences) {
          newErrors.occurrences = 'Occurrences are required.';
        } else if (formData.ends === 'after' && parseInt(formData.occurrences) <= 0) {
          newErrors.occurrences = 'Occurrences must be a positive number.';
        }
      }

      if (formData.repeatUnit === 'WEEKLY' && formData.selectedDays.length === 0) {
        newErrors.selectedDays = 'At least one day must be selected for weekly repeats.';
      }
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };


  const formatDate = (date: string) => {
    const d = new Date(date);
    return d.toISOString().split('T')[0]; // YYYY-MM-DD format
  };

  const formatTime = (time: string) => {
    const [hourString, minuteString] = time.split(':');
    const hour = parseInt(hourString);
    // const minute = parseInt(minuteString);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
    return `${formattedHour}:${minuteString} ${ampm}`;
  };

  const handleJobTypeChange = (selectedJobType: string) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedJobType: selectedJobType as JobType,
    }));
  };

  const getDisplayValue = (value: any) => (value === null || value === undefined ? 'N/A' : value);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setSuccess(false);
    setFormError('');

    if (validateForm()) {
      setLoading(true);
      try {
        const contractor = contractors.find(c => c._id === formData.selectedContractor);
        const jobDetails: CreateJobRequest = {
          id: formData.propertyListing,
          propertyId: formData.propertyListing,
          title: formData.taskTitle,
          description: formData.taskDescription,
          jobType: formData.selectedJobType,
          urgency: "Low",
          bubbleContractorId: contractor ? contractor._id : '',
          importantOrgId: importantOrgId,
          contractorId: contractor ? contractor._id : '',
          startDate: selectedDate || formatDate(new Date().toISOString()),
          startTime: formatTime(formData.startTime),
          endTime: formatTime(formData.endTime),
          repeatEvery: formData.repeat ? formData.repeatUnit.toUpperCase() : "DAILY",
          repeatDaysInWeek: formData.repeat ? formData.selectedDays : [],
          endDate: formData.repeat && formData.ends === 'on' ? formatDate(formData.endDate) : formatDate(new Date(new Date(selectedDate).getTime() + 86400000).toISOString()),
          totalOccurrences: formData.repeat
            ? formData.ends === 'after'
              ? parseInt(formData.occurrences) || 1
              : formData.ends === 'on'
                ? 0
                : 1
            : 1,
          rate: parseFloat(formData.rate as string)
        };

        // console.log('Job Details!!!:', jobDetails);

        let newJobId: string | null = null;

        const response = await createJob(jobDetails);

        if (response.success && response.data && response.data.length > 0) {
          newJobId = response.data[0].jobId.id;
        }

        await onJobCreated(selectedDate, newJobId, selectedPage);

        setSuccess(true);

        setFormData({
          propertyListing: property?.id || '',
          selectedJobType: 'Cleaning',
          selectedContractor: contractors[0]?._id || '',
          startTime: '',
          endTime: '',
          taskTitle: '',
          taskDescription: '',
          rate: '',
          repeat: false,
          repeatInterval: 1,
          repeatUnit: 'DAILY',
          selectedDays: [],
          ends: 'never',
          endDate: '',
          occurrences: ''
        });

        setTimeout(() => {
          onClose();
        }, 2000);

      } catch (error) {
        console.error('Error submitting the form', error);
        setFormError('Failed to create job. Please try again.');
      } finally {
        setLoading(false);
      }
    } else {
      setFormError('Please correct the errors in the form.');
    }
  };

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess(false);
      }, 2000);

      return () => clearTimeout(timer);
    } else if (formError) {
      const errorTimer = setTimeout(() => {
        setFormError('');
      }, 5000);

      return () => clearTimeout(errorTimer);
    }
  }, [success, formError]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const contractorsResponse: ContractorsResponse = await fetchAssociatedContractors(importantOrgId, property?.id || '');
        if (contractorsResponse.data) {
          const contractorsData: Contractor[] = contractorsResponse.data;
          setContractors(contractorsData);
          setFormData((prevData) => ({
            ...prevData,
            selectedContractor: contractorsData[0]?._id || '',
          }));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [property, importantOrgId]);

  useEffect(() => {
    if (formError && errorRef.current) {
      errorRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [formError]);

  useEffect(() => {
    if (success && successRef.current) {
      successRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [success]);

  return (
    <div className={`sidebar ${sideBar ? 'open' : ''}`}>
      <div className="sidebar-header" ref={successRef}>
        <h2>Create A Job</h2>
        <div className="sidebar-header-icons">
          <button className="close-button" onClick={onClose}><img src={xIcon} alt="x-icon" className='x-icon' /></button>
        </div>
      </div>
      {success && (
        <div className="success-message sm-20">
          <FontAwesomeIcon icon={faCheckCircle} /> Job successfully created!
        </div>
      )}
      {/* {formError && (
        <div className="error-message" ref={errorRef}>
          <FontAwesomeIcon icon={faTimesCircle} /> {formError}
        </div>
      )} */}

      <div className="sidebar-body">
        <form onSubmit={handleSubmit} className="sidebar-content-job">
          <div>
            <div className='job-row'>
              <div className='booking-column'>
                <div className="booking-label">Date</div>
                <div className="booking-value">{moment(selectedDate).format('MMM DD, YYYY')}</div>
              </div>
            </div>
            <div className="task-form px-3">
              <div className="form-group">
                <div className='property-row'>
                  <div className="booking-label">Property Listing</div>
                  <div className="property pointer" onClick={() => window.open(`https://mypropertystack.io/property_details/${property?.id}`, '_blank')}>
                    <div className={property?.image ? "listing-image" : "listing-image-none"}>
                      <img src={property?.image || homeDark} alt={property?.name || 'Property'} />
                    </div>
                    <div>
                      <h3>{getDisplayValue(property?.name)}</h3>
                      <p>{getDisplayValue(property?.address)}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="jobType">Job Type*</label>
                <div className="custom-select icon-select">
                  <CustomDropdown
                    options={jobTypeOptions}
                    value={formData.selectedJobType}
                    onChange={handleJobTypeChange}
                  />
                  {errors.selectedJobType && <div className="error-message">{errors.selectedJobType}</div>}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="contractor">Contractor*</label>
                <div className="custom-select initials-select">
                  <select
                    id="contractor"
                    name="selectedContractor"
                    value={formData.selectedContractor}
                    onChange={handleChange}
                  >
                    {contractors.map((contractor) => (
                      <option value={contractor._id} key={contractor._id}>
                        {contractor['POC: Full Name'] || contractor['DETAILS: Company Name']}
                      </option>
                    ))}
                  </select>
                  <div className="contractor-initials">{getInitials(contractors.find(c => c._id === formData.selectedContractor)?.['POC: Full Name'] || '')}</div>
                  {errors.selectedContractor && <div className="error-message">{errors.selectedContractor}</div>}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="time">Start - Finish Time*</label>
                <div className="time-input">
                  <input
                    type="text"
                    id="startTime"
                    name="startTime"
                    placeholder="HH:MM AM/PM"
                    value={formData.startTime}
                    onChange={handleChange}
                    onFocus={(e) => (e.target.type = "time")}
                    onBlur={(e) => (e.target.type = "text")}
                  />
                  <img src={clockIcon} alt="Job Type Icon" className="clock-icon" />
                  <input
                    type="text"
                    id="endTime"
                    name="endTime"
                    placeholder="HH:MM AM/PM"
                    value={formData.endTime}
                    onChange={handleChange}
                    onFocus={(e) => (e.target.type = "time")}
                    onBlur={(e) => (e.target.type = "text")}
                  />
                </div>
                <div className="time-input-error">
                  {errors.startTime && <div className="error-message">{errors.startTime}</div>}
                  {errors.endTime && <div className="error-message">{errors.endTime}</div>}
                </div>
              </div>


              <div className="form-group">
                <label htmlFor="rate">Rate*</label>
                <div className="rate-input">
                  <input
                    type="number"
                    id="rate"
                    name="rate"
                    value={formData.rate}
                    onChange={handleChange}
                    placeholder="0"
                    className="no-spinner"
                  />
                </div>
                {errors.rate && <div className="error-message">{errors.rate}</div>}
              </div>

              <div className="form-group">
                <label htmlFor="taskTitle">Task Title*</label>
                <input
                  type="text"
                  id="taskTitle"
                  name="taskTitle"
                  value={formData.taskTitle}
                  onChange={handleChange}
                  placeholder="Enter Task Title"
                />
                {errors.taskTitle && <div className="error-message">{errors.taskTitle}</div>}
              </div>

              <div className="form-group">
                <label htmlFor="taskDescription">Task Description</label>
                <textarea
                  id="taskDescription"
                  name="taskDescription"
                  value={formData.taskDescription}
                  onChange={handleChange}
                  placeholder="Enter Task Description"
                  rows={4}
                ></textarea>
                {errors.taskDescription && <div className="error-message">{errors.taskDescription}</div>}
              </div>

              <div className="form-group">
                <label>Repeat</label>
                <div className="switch-container">
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="repeat"
                      checked={formData.repeat}
                      onChange={handleChange}
                    />
                    <span className="slider"></span>
                  </label>
                  <span className={`switch-label ${formData.repeat ? 'confirmed' : ''}`}>{formData.repeat ? 'Yes' : 'No'}</span>
                </div>
              </div>

              {formData.repeat && (
                <>
                  <div className="form-group">
                    <label htmlFor="repeatInterval">Repeat every</label>
                    <div className="repeat-every">
                      {/* <input
                        type="number"
                        id="repeatInterval"
                        name="repeatInterval"
                        min="1"
                        value={formData.repeatInterval}
                        onChange={handleChange}
                        style={{ width: '40%' }}
                      /> */}
                      <select
                        id="repeatIntervalUnit"
                        name="repeatUnit"
                        value={formData.repeatUnit}
                        onChange={handleChange}
                      >
                        <option value="DAILY">Daily</option>
                        <option value="WEEKLY">Weekly</option>
                        <option value="MONTHLY">Monthly</option>
                        <option value="YEARLY">Yearly</option>
                      </select>
                    </div>
                    {errors.repeatUnit && <div className="error-message">{errors.repeatUnit}</div>}
                  </div>

                  {formData.repeatUnit === "WEEKLY" && (
                    <div className="form-group">
                      <label>Repeat on</label>
                      <div className="repeat-days">
                        {daysOfWeek.map((day, index) => (
                          <label
                            key={index}
                            className={`day-checkbox ${formData.selectedDays.includes(day.full) ? "selected" : ""
                              }`}
                          >
                            <input
                              type="checkbox"
                              value={day.full}
                              checked={formData.selectedDays.includes(day.full)}
                              onChange={() => handleDaySelection(day.full)}
                            />
                            {day.short}
                          </label>
                        ))}
                      </div>
                      {errors.selectedDays && <div className="error-message">{errors.selectedDays}</div>}
                    </div>
                  )}


                  <div className="form-group">
                    <label>Ends</label>
                    <div className="ends-options">
                      {/* <label>
                        <input
                          type="radio"
                          name="ends"
                          value="never"
                          checked={formData.ends === 'never'}
                          onChange={handleChange}
                        />
                        <span className="custom-radio"></span>
                        <span>Never</span>
                      </label> */}
                      <label>
                        <input
                          type="radio"
                          name="ends"
                          value="on"
                          checked={formData.ends === 'on'}
                          onChange={handleChange}
                        />
                        <span className="custom-radio"></span>
                        <span style={{ minWidth: '40%' }}>On</span>
                        {formData.ends === 'on' && (
                          // <input
                          //   type="date"
                          //   name="endDate"
                          //   value={formData.endDate}
                          //   onChange={handleChange}
                          //   className="ends-date-input"
                          //   style={{ width: '50%' }}
                          // />
                          <DatePicker
                            selected={formData.endDate ? new Date(formData.endDate) : null}
                            onChange={(date) => handleDateChange(date, 'endDate')}
                            dateFormat="MM/dd/yyyy"
                            placeholderText="Select End Date"
                          // className='date-picker-half-width'
                          />
                        )}
                        {/* {errors.endDate && <div className="error-message">{errors.endDate}</div>} */}
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="ends"
                          value="after"
                          checked={formData.ends === 'after'}
                          onChange={handleChange}
                        />
                        <span className="custom-radio"></span>
                        <span style={{ minWidth: '40%' }}>After</span>
                        {formData.ends === 'after' && (
                          <>
                            <input
                              type="number"
                              name="occurrences"
                              value={formData.occurrences}
                              onChange={handleChange}
                              className="ends-occurrences-input"
                              style={{ width: '50%' }}
                            />
                          </>
                        )}

                      </label>
                    </div>
                  </div>

                  {(errors.ends && <div className="error-message">{errors.ends}</div>)}
                  {(errors.endDate && <div className="error-message">{errors.endDate}</div>)}
                  {errors.occurrences && <div className="error-message">{errors.occurrences}</div>}
                </>
              )}

              {formError && (
                <div className="error-message" ref={errorRef}>
                  <FontAwesomeIcon icon={faTimesCircle} /> {formError}
                </div>
              )}

              <div className="modal-footer">
                <div className="form-actions">
                  <button type="submit" className="primary-btn font-500" disabled={loading}>
                    {loading ? (
                      <>
                        <FontAwesomeIcon icon={faSpinner} spin /> Processing...
                      </>
                    ) : (
                      'Create Job'
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div >
    </div >
  );
};

export default JobSidebar;
