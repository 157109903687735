import React from 'react';
import { PropertyList } from '../api/type';
import homeDark from './assets/home-dark.svg';
import SkeletonRow from './dropdown/SkeletonRow';

type ListingProps = {
  listing: PropertyList | undefined;
  loading: boolean;
};

const truncateText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
};

const Listing: React.FC<ListingProps> = ({ listing, loading }) => {
  if (loading) {
    return (
      <>
        {Array.from({ length: 10 }).map((_, index) => (
          <SkeletonRow key={index} type="listing" />
        ))}
      </>
    );
  }

  return (
    <div className="listing-container">
      <div className={listing?.image ? 'listing-image' : 'listing-image-none'}>
        <img src={listing?.image || homeDark} alt={listing?.name || 'Property'} />
      </div>

      <div>
        <h3>{truncateText(listing?.name || '', 30)}</h3>
        <p>{truncateText(listing?.address || '', 70)}</p>
      </div>
    </div>
  );
};

export default React.memo(Listing);


// <div className="listing pointer" onClick={() => window.open(https://mypropertystack.io/property_details/${listing?.id}, '_blank')}>