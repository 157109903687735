import React, { useState, useRef, useEffect } from 'react';

interface CustomMonthDropdownProps {
  options: string[];
  selectedOption: string | undefined;
  onSelect: (option: string) => void;
  disabled?: boolean;
}

const CustomMonthDropdown: React.FC<CustomMonthDropdownProps> = ({ options, selectedOption, onSelect, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const selectedOptionRef = useRef<HTMLLIElement>(null);

  const handleToggle = () => {
    if (!disabled) setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: string) => {
    onSelect(option);
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen && selectedOptionRef.current) {
      selectedOptionRef.current.scrollIntoView({
        block: 'center',
        inline: 'center',
      });
    }
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="month-dropdown-container mx-3" ref={dropdownRef}>
      <div className="month-dropdown" onClick={handleToggle}>
        {selectedOption || 'Select Month'}
      </div>
      {isOpen && (
        <ul className="month-dropdown-options">
          {options.map((option) => (
            <li
              key={option}
              className={`month-option ${option === selectedOption ? 'selected' : ''}`}
              onClick={() => handleOptionClick(option)}
              ref={option === selectedOption ? selectedOptionRef : null}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomMonthDropdown;
