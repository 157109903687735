import './SkeletonRow.css';

interface SkeletonRowProps {
  type: 'listing' | 'calendar';
}

const SkeletonRow: React.FC<SkeletonRowProps> = ({ type }) => {
  return type === 'calendar' ? (
    <div className="skeleton-calendar pointer skeleton-listing">
      {/* {Array.from({ length: 9 }).map((_, index) => (  key={`calendar-skeleton-${index}`}*/}
      <div className="listing-container">
        <div className="skeleton-texts">
          <div className="calendar-skeleton-subtitle"></div>
        </div>
      </div>
      {/* ))} */}
    </div>
  ) : (
    <div className="skeleton-property-list pointer">
      <div className="listing-container">
        <div className="listing-image skeleton-image"></div>
        <div className="skeleton-texts">
          <div className="skeleton-title"></div>
          <div className="skeleton-subtitle"></div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonRow;
