import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import GooglePlacesAutocomplete from '../dropdown/GooglePlacesAutocomplete';
import xIcon from '../assets/x.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

interface FindAvailabilityModalProps {
  onClose: () => void;
  updateProperties: (
    checkInDate: string,
    checkOutDate: string,
    city: string,
    latitude: number | null,
    longitude: number | null,
    guestCount: number | null
  ) => void;
}

const FindAvailabilityModal: React.FC<FindAvailabilityModalProps> = ({ onClose, updateProperties }) => {
  const [checkInDate, setCheckInDate] = useState<string>('');
  const [checkOutDate, setCheckOutDate] = useState<string>('');
  const [guestCount, setGuestCount] = useState<number | null>(null);
  const [city, setCity] = useState<string>('');
  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);

  const [clearTrigger, setClearTrigger] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [formError, setFormError] = useState<string>('');

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};

    if (!checkInDate) newErrors.checkInDate = 'Check In Date is required.';
    if (!checkOutDate) newErrors.checkOutDate = 'Check Out Date is required.';
    if (checkInDate && checkOutDate) {
      const checkIn = new Date(checkInDate);
      const checkOut = new Date(checkOutDate);

      if (checkIn >= checkOut) newErrors.checkInDate = 'Check In Date must be before Check Out Date.';
      if ((checkOut.getTime() - checkIn.getTime()) < 24 * 60 * 60 * 1000) {
        newErrors.checkOutDate = 'Check Out Date must be at least one day after Check In Date.';
      }
    }

    if (!city) newErrors.city = 'City is required.';

    if (guestCount === null || isNaN(guestCount)) {
      newErrors.guestCount = 'Guest count is required.';
    } else if (guestCount <= 0) {
      newErrors.guestCount = 'Guest count must be at least 1.';
    } else if (guestCount > 50) {
      newErrors.guestCount = 'Guest count must not exceed 50.';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSetCity = (location: { city: string; latitude: number; longitude: number }) => {
    setCity(location.city);
    setLatitude(location.latitude);
    setLongitude(location.longitude);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setFormError('');

    if (validateForm()) {
      setLoading(true);
      try {
        await updateProperties(
          checkInDate,
          checkOutDate,
          city,
          latitude,
          longitude,
          guestCount
        );
        onClose();
      } catch (error) {
        console.error('Error submitting the form', error);
        setFormError('Failed to find availability. Please try again.');
      } finally {
        setLoading(false);
      }
    } else {
      setFormError('Please correct the errors in the form.');
    }
  };

  const handleClear = () => {
    setCheckInDate('');
    setCheckOutDate('');
    setGuestCount(null);
    setCity('');
    setLatitude(null);
    setLongitude(null);
    setClearTrigger((prev) => !prev);
    setErrors({});
    setFormError('');
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-head">
          <button className="close-button" onClick={onClose}><img src={xIcon} alt="x-icon" className="x-icon" /></button>
        </div>
        <div className="modal-header">
          <h2>Find Availability</h2>
        </div>
        <div className="modal-body">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="checkInDate">Check In Date</label>
                <DatePicker
                  selected={checkInDate ? new Date(checkInDate) : null}
                  onChange={(date: Date | null) => {
                    setCheckInDate(date ? moment(date).format('YYYY-MM-DD') : '');
                  }}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="Select Check In Date"
                  shouldCloseOnSelect={false}
                />
                {errors.checkInDate && <div className="error-message">{errors.checkInDate}</div>}
              </div>
              <div className="form-group">
                <label htmlFor="checkOutDate">Check Out Date</label>
                <DatePicker
                  selected={checkOutDate ? new Date(checkOutDate) : null}
                  onChange={(date: Date | null) => {
                    setCheckOutDate(date ? moment(date).format('YYYY-MM-DD') : '');
                  }}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="Select Check Out Date"
                  minDate={checkInDate ? new Date(new Date(checkInDate).setDate(new Date(checkInDate).getDate() + 1)) : undefined}
                  showDisabledMonthNavigation
                  shouldCloseOnSelect={false}
                />
                {errors.checkOutDate && <div className="error-message">{errors.checkOutDate}</div>}
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="guestCount">Guest Count</label>
              <input
                type="number"
                id="guestCount"
                name="guestCount"
                value={guestCount !== null ? guestCount : ''}
                placeholder="Enter Guest Count"
                onChange={(e) => {
                  const value = e.target.value;
                  setGuestCount(value ? parseInt(value, 10) : null);
                }}
              />
              {errors.guestCount && <div className="error-message">{errors.guestCount}</div>}
            </div>
            <div className="form-group">
              <label htmlFor="city">City</label>
              <GooglePlacesAutocomplete
                setCity={handleSetCity}
                clearTrigger={clearTrigger}
              />
              {errors.city && <div className="error-message">{errors.city}</div>}
            </div>
            {formError && (
              <div className="error-message">
                <FontAwesomeIcon icon={faTimesCircle} /> {formError}
              </div>
            )}
            <div className="modal-footer">
              <div className="form-actions">
                <button
                  type="button"
                  className="secondary-btn font-500 mx-1"
                  onClick={handleClear}
                >
                  Clear
                </button>
                <button
                  type="submit"
                  className="primary-btn font-500"
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <FontAwesomeIcon icon={faSpinner} spin /> Processing...
                    </>
                  ) : (
                    'Find Availability'
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FindAvailabilityModal;
