import React, { useMemo, useCallback, useState, useRef, useEffect } from "react";
import { CalendarPMS, PropertyList, Job, ReservationHoverInfoResponse } from '../api/type';
import { getJobIcon, getImageForBookingChannel, getJobLightIcon } from "../utils/iconUtils";
import moment from 'moment';

import { fetchReservationHoverInfo } from '../api/request';

import calendarLight from './assets/calendar.svg';
import nightIcon from './assets/night.svg';
import ellipsis from './assets/ellipsis.svg';
import checkCircle from './assets/check-circle.svg';
import clock from './assets/clock-small.svg';
import userIcon from './assets/user-small.svg';
import homeDark from './assets/home-dark.svg';

type DayCellProps = {
  date: string;
  calendarPMSMap: Record<string, CalendarPMS | undefined>;
  property: PropertyList | undefined;
  jobList: any[];
  onBookingClick: (property: PropertyList | undefined, booking: CalendarPMS, pageNumber: number) => void;
  onJobClick: (property: PropertyList | undefined, date: string, pageNumber: number) => void;
  onReservationClick: (property: PropertyList | undefined, date: string, pageNumber: number) => void;
  onJobDetailClick: (job: Job, property: PropertyList | undefined) => void;
  onMoreJobDetailClick: (job: Job[], property: PropertyList | undefined) => void;
  isInRange: boolean;
  newlyAddedReservationId: string | null;
  newlyAddedJobId: string | null;
  pageNumber: number;
};

const DayCell: React.FC<DayCellProps> = ({ date, calendarPMSMap, jobList, property, onBookingClick, onJobClick, onReservationClick, onJobDetailClick, onMoreJobDetailClick, newlyAddedReservationId, isInRange, newlyAddedJobId, pageNumber }) => {
  const [showAddOptions, setShowAddOptions] = useState(false);
  const [hoverTooltipVisible, setHoverTooltipVisible] = useState(false);

  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const [hoverInfo, setHoverInfo] = useState<ReservationHoverInfoResponse | null>(null);
  const [loadingHoverInfo, setLoadingHoverInfo] = useState(false);

  const [jobTooltipVisible, setJobTooltipVisible] = useState(false);
  const [jobTooltipPosition, setJobTooltipPosition] = useState({ top: 0, left: 0 });

  const [currentJobs, setCurrentJobs] = useState<Job[]>([]);

  const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const tooltipHoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const jobTooltipHoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [newAddedReservationTooltipVisible, setNewAddedReservationTooltipVisible] = useState(false);
  const [newAddedJobTooltipVisible, setNewAddedJobTooltipVisible] = useState(false);

  const [cachedHoverInfo, setCachedHoverInfo] = useState<{ [key: string]: ReservationHoverInfoResponse | null }>({});

  const booking = calendarPMSMap[date];

  const previousDay = moment(booking?.reservationPMS?.arrivalDate).utc().subtract(1, 'day').format('YYYY-MM-DD');
  const previousBooking = calendarPMSMap[previousDay];

  const normalizedDate = moment(date).format('YYYY-MM-DD');

  const sameDayDeparture = useMemo(() => {
    return (
      booking?.reservationPMS &&
      moment(booking.reservationPMS.arrivalDate).utc().isSame(booking.reservationPMS.departureDate, 'day')
    );
  }, [booking]);

  const isBookingStart = booking?.reservationPMS && moment(booking.reservationPMS.arrivalDate).utc().format('YYYY-MM-DD') === normalizedDate;
  const isBookingEnd = booking?.reservationPMS && moment(booking.reservationPMS.departureDate).utc().format('YYYY-MM-DD') === normalizedDate && !sameDayDeparture;

  const previousSameDayDeparture = useMemo(() => {
    if (!booking?.reservationPMS) return false;

    if (!previousBooking || !previousBooking.reservationPMS) return false;

    const arrivalDate = moment(booking.reservationPMS.arrivalDate).utc();
    const departureDate = moment(previousBooking.reservationPMS.departureDate).utc();

    return arrivalDate.isSame(departureDate, 'day');
  }, [booking, calendarPMSMap]);

  const previousBookingExists = useMemo(() => {
    if (!booking?.reservationPMS) return false;

    if (!previousBooking || !previousBooking.reservationPMS) return false;

    const arrivalDate = moment(booking.reservationPMS.arrivalDate).utc();
    const previousArrivalDate = moment(previousBooking.reservationPMS.arrivalDate).utc();
    const previousDepartureDate = moment(previousBooking.reservationPMS.departureDate).utc();

    return arrivalDate.isBetween(previousArrivalDate, previousDepartureDate, 'day')
  }, [booking, calendarPMSMap]);

  const isPreviousBookingBlock = useMemo(() => {
    return (
      ['Manual Block', 'Advance Notice', 'After Block', 'Before Block', 'Rolling Window', 'Rolling window'].includes(
        previousBooking?.reservationPMS?.otaName || ''
      )
    );
  }, [previousBooking]);

  const isAnyBlock = useMemo(() => {
    return (
      ['Manual Block', 'Advance Notice', 'After Block', 'Before Block', 'Rolling Window', 'Rolling window'].includes(
        booking?.reservationPMS?.otaName || ''
      )
    );
  }, [booking]);

  const isAnyReservation = useMemo(() => {
    return (
      ['Direct', 'Booking.com', 'booking.com', 'VRBO', 'Owners Block', 'Airbnb'].includes(
        booking?.reservationPMS?.otaName || ''
      )
    );
  }, [booking]);

  const fetchHoverInfo = async (reservationId: string) => {
    if (cachedHoverInfo[reservationId]) {
      setHoverInfo(cachedHoverInfo[reservationId]);
      setLoadingHoverInfo(false);
      return;
    }
    try {
      setLoadingHoverInfo(true);
      const data = await fetchReservationHoverInfo(reservationId);
      setHoverInfo(data);
      setCachedHoverInfo(prev => ({ ...prev, [reservationId]: data }));
    } catch (error) {
      console.error('Error fetching hover info:', error);
    } finally {
      setLoadingHoverInfo(false);
    }
  };

  useEffect(() => {
    if (tooltipVisible && booking?.reservationPMS?.id) {
      fetchHoverInfo(booking.reservationPMS.id);
    }
  }, [tooltipVisible, booking?.reservationPMS?.id, fetchHoverInfo]);

  useEffect(() => {
    if (
      booking &&
      booking.reservationPMS?.id &&
      booking.reservationPMS.id === newlyAddedReservationId &&
      date === moment(booking.reservationPMS.arrivalDate).utc().format('YYYY-MM-DD')
    ) {
      setNewAddedReservationTooltipVisible(true);
      const timer = setTimeout(() => {
        setNewAddedReservationTooltipVisible(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [newlyAddedReservationId, booking, date]);

  const openBookingSidebar = (property: PropertyList | undefined, booking: CalendarPMS) => {
    if (booking) {
      onBookingClick(property, booking, pageNumber);
    }
  };

  const openCreateJob = (property: PropertyList | undefined, date: string) => {
    onJobClick(property, date, pageNumber);
  };

  const openCreateReservation = (property: PropertyList | undefined, date: string) => {
    onReservationClick(property, date, pageNumber);
  };

  const openJobDetailSidebar = (job: Job, property: PropertyList | undefined) => {
    if (job) {
      onJobDetailClick(job, property);
    }
  };

  const openMoreDetailSidebar = (jobList: Job[], property: PropertyList | undefined) => {
    if (jobList) {
      onMoreJobDetailClick(jobList, property);
    }
  }

  const handleClick = useCallback(() => {
    setShowAddOptions(true);
  }, []);

  const handleLeave = useCallback(() => {
    setShowAddOptions(false);
  }, []);

  const handleCellEnter = useCallback(() => {
    if ((showAddOptions === false && booking?.reservationPMS) || (showAddOptions === false && jobList.length > 0)) {
      setHoverTooltipVisible(true);
    } else {
      const timeoutId = setTimeout(() => {
        handleClick();
      }, 400);
      hoverTimeoutRef.current = timeoutId;
    }
  }, [showAddOptions, booking, jobList, handleClick]);

  const handleCellLeave = useCallback(() => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
      hoverTimeoutRef.current = null;
    }
    handleLeave();
    setHoverTooltipVisible(false);
  }, [handleLeave]);

  const handleMouseEnter = useCallback((event: React.MouseEvent) => {
    if (booking && booking.reservationPMS && event.currentTarget) {
      const target = event.currentTarget;
      const timeoutId = setTimeout(() => {
        if (target) {
          const rect = target.getBoundingClientRect();
          let top = 35;
          let left = 30;
          if (rect.left > 1200) {
            left = -295;
            top = -20;
          }
          if (rect.top < 250 && rect.left > 1200) {
            left = -295;
            top = -20;
          }

          if (rect.top > 550) {
            top = -445;
          }
          if ((rect.top > 275 && rect.top < 282) &&
            (booking?.reservationPMS?.otaName === 'Direct' ||
              booking?.reservationPMS?.otaName === 'Booking.com' ||
              booking?.reservationPMS?.otaName === 'booking.com' ||
              booking?.reservationPMS?.otaName === 'VRBO' ||
              booking?.reservationPMS?.otaName === 'Airbnb' ||
              booking?.reservationPMS?.otaName === 'Owners Block')) {
            top = -445;
          }

          if ((rect.top > 345 && rect.top < 350) &&
            (booking?.reservationPMS?.otaName === 'Direct' ||
              booking?.reservationPMS?.otaName === 'Booking.com' ||
              booking?.reservationPMS?.otaName === 'booking.com' ||
              booking?.reservationPMS?.otaName === 'VRBO' ||
              booking?.reservationPMS?.otaName === 'Airbnb' ||
              booking?.reservationPMS?.otaName === 'Owners Block'
            )) {
            top = -445;
          }

          if (rect.top > 550 && (booking?.reservationPMS?.otaName === 'Manual Block' || booking?.reservationPMS?.otaName === 'After Block' || booking?.reservationPMS?.otaName === 'Before Block' || booking?.reservationPMS?.otaName === 'Rolling window' || booking?.reservationPMS?.otaName === 'Advance Notice')) {
            top = -295;
          }

          setTooltipPosition({ top: top, left: left });
          setTooltipVisible(true);
        }
      }, 400);
      tooltipHoverTimeoutRef.current = timeoutId;
    }
  }, [booking]);
  const handleMouseLeave = useCallback(() => {
    if (tooltipHoverTimeoutRef.current) {
      clearTimeout(tooltipHoverTimeoutRef.current);
      tooltipHoverTimeoutRef.current = null;
    }
    setTooltipVisible(false);
  }, []);

  const handleJobMouseEnter = useCallback((event: React.MouseEvent, job: Job) => {
    if (event.currentTarget) {
      const target = event.currentTarget;
      const timeoutId = setTimeout(() => {
        if (target) {
          const rect = target.getBoundingClientRect();
          setTooltipVisible(false);
          let top = 45;
          let left = 10;

          // console.log(rect.top);
          if (rect.left > 1200) {
            left = -310;
            top = -170;
          }
          if (rect.top < 250 && rect.left > 1200) {
            left = -310;
            top = -50;
          }
          if (rect.top > 250 && rect.top < 425) {
            top = 35;
          }
          if (rect.top > 450) {
            top = -300;
          }
          if (hoverTooltipVisible) {
            setHoverTooltipVisible(false);
          }
          setJobTooltipVisible(true);
          setJobTooltipPosition({ top: top, left: left });
          setCurrentJobs([job]);
        }
      }, 400);
      jobTooltipHoverTimeoutRef.current = timeoutId;
    }
  }, [hoverTooltipVisible]);

  const handleJobMouseLeave = useCallback(() => {
    if (jobTooltipHoverTimeoutRef.current) {
      clearTimeout(jobTooltipHoverTimeoutRef.current);
      jobTooltipHoverTimeoutRef.current = null;
    }
    setCurrentJobs([]);
    setJobTooltipVisible(false);
  }, []);

  const handleMoreJobsMouseEnter = useCallback((event: React.MouseEvent) => {
    if (event.currentTarget) {
      const target = event.currentTarget;
      const timeoutId = setTimeout(() => {
        if (target) {
          const rect = target.getBoundingClientRect();
          if (hoverTooltipVisible) {
            setHoverTooltipVisible(false);
          }
          setJobTooltipVisible(true);
          let top = -50;
          let left = jobList.length === 2 ? 85 : 75;

          if (rect.left > 1200) {
            left = -310;
            top = -170;
          }
          if (rect.top < 250 && rect.left > 1200) {
            left = -310;
            top = -50;
          }
          if (rect.top > 425) {
            top = -270;
          }
          setJobTooltipPosition({ top: top, left: left });
          setCurrentJobs(jobList);
        }
      }, 400);
      jobTooltipHoverTimeoutRef.current = timeoutId;
    }
  }, [hoverTooltipVisible, jobList]);

  const handleMoreJobsMouseLeave = useCallback(() => {
    if (jobTooltipHoverTimeoutRef.current) {
      clearTimeout(jobTooltipHoverTimeoutRef.current);
      jobTooltipHoverTimeoutRef.current = null;
    }
    setJobTooltipVisible(false);
  }, []);

  return (
    <td className={`table-day-cell  ${isInRange ? '' : 'day-cell-disabled'}
    ${((previousBooking?.reservationPMS && booking?.reservationPMS) ? '' : '')} `}
      onMouseEnter={isInRange ? handleCellEnter : undefined}
      onMouseLeave={isInRange ? handleCellLeave : undefined}
      onClick={isInRange ? handleClick : undefined}
    >
      <div className="day-cell-container">
        {booking && booking.reservationPMS ? (
          <>
            {jobList.length > 0 && (
              <div className={`jobs`}>
                {jobList.length === 1 ? (
                  <div
                    key={0}
                    className="job-icon"
                    onMouseEnter={(event) => handleJobMouseEnter(event, jobList[0])}
                    onMouseLeave={handleJobMouseLeave}
                    onClick={() => openJobDetailSidebar(jobList[0], property)}
                  >
                    <img src={getJobIcon(jobList[0].jobType)} alt={jobList[0].jobType} className="job-icon-img" />
                  </div>
                ) : (
                  jobList.length > 1 && (
                    <>
                      {jobList.slice(0, 1).map((job, index) => (
                        <div
                          key={index}
                          className="job-icon"
                          onMouseEnter={handleMoreJobsMouseEnter}
                          onMouseLeave={handleMoreJobsMouseLeave}
                          onClick={() => openMoreDetailSidebar(jobList, property)}
                        >
                          <img src={getJobIcon(job.jobType)} alt={job.jobType} className="job-icon-img" />
                        </div>
                      ))}
                      <div className="more">
                        <span className="more-jobs">{jobList.length - 1}</span>
                      </div>
                    </>
                  )
                )}
              </div>
            )}
          </>
        ) : (
          jobList.length > 0 && (

            <div className={`jobs ${jobList.length > 2 ? 'width-90' : jobList.length > 1 ? 'width-50' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              {jobList.length === 1 ? (
                <div
                  className="job-icon"
                  onMouseEnter={(event) => handleJobMouseEnter(event, jobList[0])}
                  onMouseLeave={handleJobMouseLeave}
                  onClick={() => openJobDetailSidebar(jobList[0], property)}
                >
                  <img src={getJobIcon(jobList[0].jobType)} alt={jobList[0].jobType} className="job-icon-img" />
                </div>
              ) : (
                <>
                  {jobList.slice(0, jobList.length === 3 ? 3 : 2).map((job, index) => (
                    <div
                      key={index}
                      className="job-icon"
                      onMouseEnter={handleMoreJobsMouseEnter}
                      onMouseLeave={handleMoreJobsMouseLeave}
                      onClick={() => openMoreDetailSidebar(jobList, property)}
                    >
                      <img src={getJobIcon(job.jobType)} alt={job.jobType} className="job-icon-img" />
                    </div>
                  ))}
                  {jobList.length > 3 && (
                    <>
                      <div
                        className="job-icon"
                        onMouseEnter={handleMoreJobsMouseEnter}
                        onMouseLeave={handleMoreJobsMouseLeave}
                        onClick={() => openMoreDetailSidebar(jobList, property)}
                      >
                        <img src={ellipsis} alt='ellipsis-icon' />
                      </div>
                      <div className="more">
                        <span className={`${!showAddOptions ? 'more-jobs' : ''}`}>{jobList.length - 2}</span>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>

          )
        )}

        {booking ? (
          booking.reservationPMS ? (
            <>
              {isAnyBlock ? (
                <>
                  {previousSameDayDeparture && (
                    <div
                      className={`${isPreviousBookingBlock
                        ? 'manual-end-and-manual'
                        : 'booking-end-and-job'
                        }`}
                    >
                    </div>
                  )}

                  {previousBookingExists && (
                    <div
                      className={`${isPreviousBookingBlock
                        ? 'manual-block'
                        : 'booking'
                        }`}
                    >
                    </div>
                  )}
                  <div
                    className={`manual-block z-index-1 
                        ${sameDayDeparture ? 'booking-same-day' :
                        isBookingStart ?
                          (jobList.length > 0 ? 'job-and-booking-start' : 'booking-start')
                          : ''} 
                        ${isBookingEnd ? (jobList.length === 0 ? 'booking-end' : 'booking-end') : ''}`
                    }
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => openBookingSidebar(property,
                      booking
                    )}
                  ></div>
                </>
              ) : (
                <>
                  {previousSameDayDeparture && (
                    <div
                      className={`${isPreviousBookingBlock
                        ? 'manual-end-and-manual'
                        : 'booking-end-and-job'
                        }`}
                    >
                    </div>
                  )}

                  {previousBookingExists && (
                    <div
                      className={`${isPreviousBookingBlock
                        ? 'manual-block'
                        : 'booking'
                        }`}
                    >
                    </div>
                  )}

                  <div
                    className={`booking ${sameDayDeparture
                      ? (jobList.length > 0 ? 'job-and-booking-start booking-same-day' : 'booking-same-day')
                      : isBookingStart
                        ? (jobList.length > 0 ? 'job-and-booking-start' : 'booking-start')
                        : ''} ${isBookingEnd
                          ? (jobList.length === 0 ? 'booking-end' : 'booking-end')
                          : ''
                      }`}
                    onClick={() => openBookingSidebar(property,
                      booking
                    )}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    {isBookingStart && (
                      <div className="booking-start-content">
                        <div className="circle-image">
                          <img src={getImageForBookingChannel(booking.reservationPMS.otaName ? booking.reservationPMS.otaName : '')} alt="Guest" />
                        </div>
                        <div className="booking-details">
                          <p className="guest-name">
                            {(() => {
                              const fullName = booking.reservationPMS.guestFullName || "";
                              const nameParts = fullName.split(" ");
                              var firstName = nameParts[0];
                              const lastNameInitial = nameParts[1] ? `${nameParts[1][0]}.` : "";
                              var firstNameModified = '';
                              if (firstName.length > 6 && jobList.length > 0) {
                                firstNameModified = `${firstName.slice(0, 6)}`;
                                return `${firstNameModified} `;
                              } else {
                                return `${firstName} ${lastNameInitial}`;
                              }
                            })()}
                          </p>
                          <p className="guest-count-label">{booking.reservationPMS.totalGuests} Guests</p>
                          <p className="stay-details">{booking.reservationPMS.totalNights} Nights</p>
                        </div>
                      </div>
                    )}
                  </div>

                </>
              )}

              {(!isBookingEnd && showAddOptions) && (
                <div className={`add-options ${jobList.length >= 2 ? 'z-index-10' : 'z-index-10'}`}>
                  <p onClick={() => openCreateJob(property, date)}>+ Add Job</p>
                </div>
              )}

              {(isBookingEnd && showAddOptions) && (
                <div className={`add-options`}>
                  <p onClick={() => openCreateJob(property, date)}>+ Add Job</p>
                  <div className="separator">
                    <span>or</span>
                  </div>
                  <p onClick={() => openCreateReservation(property, date)}>+ Add Reservation</p>
                </div>
              )}
            </>
          ) : (
            <>
              {showAddOptions ? (
                <div className={`add-options`}>
                  <p onClick={() => openCreateJob(property, date)}>+ Add Job</p>
                  <div className="separator">
                    <span>or</span>
                  </div>
                  <p onClick={() => openCreateReservation(property, date)}>+ Add Reservation</p>
                </div>
              ) : (

                <div className="availability">
                  <p className="price">${Math.round(booking.price)}</p>
                  <p className="stay">
                    <span className="mx-half-right">
                      <img src={nightIcon} alt="night-icon" height={8} width={8} />
                    </span>
                    {booking.minStay}
                  </p>
                </div>
              )}

            </>
          )
        ) : (
          <>
            {showAddOptions ? (
              <div className={`add-options`}>
                <p onClick={() => openCreateJob(property, date)}>+ Add Job</p>
                <div className="separator">
                  <span>or</span>
                </div>
                <p onClick={() => openCreateReservation(property, date)}>+ Add Reservation</p>
              </div>
            ) : (
              <div className="availability">
                <p className="stay">
                  <img src={nightIcon} alt="night-icon" height={8} width={8} />
                </p>
              </div>
            )}
          </>
        )}

        {
          tooltipVisible && booking && booking.reservationPMS && property && (
            <div className="tooltip" style={{ top: tooltipPosition.top, left: tooltipPosition.left }} onClick={() => setTooltipVisible(false)}>
              <div className="tooltip-content">
                <div className="reservation-tooltip-container">
                  <div className="reservation-tooltip-info">
                    <div className="reservation-icon"><img src={calendarLight} alt="Calendar Icon" /></div>
                    <div className="reservation-value">Reservation - {booking.reservationPMS.otaName}</div>
                  </div>
                  <div className="reservation-tooltip-info">
                    <div className="confirmed-icon"><img src={checkCircle} alt="Calendar Icon" /></div>
                    <div className="reservation-value confirmed">{booking.reservationPMS.reservationStatus}</div>
                  </div>
                </div>
                <div className="reservation-info">
                  <div className='reservation-container'>
                    <div className="reservation-dates">
                      <p>{moment(booking.reservationPMS.arrivalDate).utc().format('MMM D YYYY')} - {moment(booking.reservationPMS.departureDate).utc().format('MMM D YYYY')}</p>
                      <div className="time-info">
                        {/* <span className='mr-3'><img src={clock} alt="Clock Icon" /> {moment(booking.reservationPMS.arrivalDate).utc().format('h:mm A')}</span>
                      <span><img src={clock} alt="Clock Icon" /> {moment(booking.reservationPMS.departureDate).utc().format('h:mm A')}</span> */}
                        <span className='mr-3'><img src={clock} alt="Clock Icon" /> 03:00 PM</span>
                        <span><img src={clock} alt="Clock Icon" /> 10:00 AM </span>
                      </div>
                    </div>
                    {booking.reservationPMS.otaName !== 'Manual Block' && booking.reservationPMS.otaName !== 'Advance Notice' && booking.reservationPMS.otaName !== 'After Block' && booking.reservationPMS.otaName !== 'Before Block' && booking.reservationPMS.otaName !== 'Rolling Window' && booking.reservationPMS.otaName !== 'Rolling window' && (
                      <>
                        <div className="reservation-tooltip-info">
                          <div className="reservation-icon"> <img src={nightIcon} alt="Half Moon Icon" className='night-icon' /></div>
                          <div className="reservation-value">{booking.reservationPMS.totalDays} Days {booking.reservationPMS.totalNights} Nights</div>
                        </div>
                        <div className="reservation-tooltip-info">
                          <div className="reservation-icon"><img src={userIcon} alt="User Small" className='user-icon' /></div>
                          <div className="reservation-value">{booking.reservationPMS.totalGuests} Guests</div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className='property-dark-row'>
                  <div className="property-dark">
                    <div className={property.image ? "listing-image" : "listing-image-none"}>
                      <img src={property.image || homeDark} alt={property.name || 'Property'} />
                    </div>
                    <div>
                      <h3>{property.name}</h3>
                      <p>{property.address}</p>
                    </div>
                  </div>
                </div>
                {
                  booking.reservationPMS.otaName !== 'Manual Block' &&
                  booking.reservationPMS.otaName !== 'Advance Notice' &&
                  booking.reservationPMS.otaName !== 'After Block' &&
                  booking.reservationPMS.otaName !== 'Before Block' &&
                  booking.reservationPMS.otaName !== 'Rolling Window' &&
                  booking.reservationPMS.otaName !== 'Rolling window' && (
                    <div className="amounts-container">
                      <div className="amounts">
                        <div>
                          {loadingHoverInfo ? (
                            <div className="loading-spinner-container">
                              <div className="spinner-tooltip">
                                <div className="double-bounce1"></div>
                                <div className="double-bounce2"></div>
                              </div>
                            </div>
                          ) : (
                            <p className="amount-value due">
                              ${hoverInfo?.data?.balanceDue ?? '0.00'}
                            </p>
                          )}
                          <p className="amount-label">Balance Due</p>
                        </div>
                        <div>
                          {loadingHoverInfo ? (
                            <div className="loading-spinner-container">
                              <div className="spinner-tooltip">
                                <div className="double-bounce1"></div>
                                <div className="double-bounce2"></div>
                              </div>
                            </div>
                          ) : (
                            <p className="amount-value">
                              ${hoverInfo?.data?.totalPaid ?? '0.00'}
                            </p>
                          )}
                          <p className="amount-label">Paid</p>
                        </div>
                        <div>
                          {loadingHoverInfo ? (
                            <div className="loading-spinner-container">
                              <div className="spinner-tooltip">
                                <div className="double-bounce1"></div>
                                <div className="double-bounce2"></div>
                              </div>
                            </div>
                          ) : (
                            <p className="amount-value">
                              ${hoverInfo?.data?.payout ?? '0.00'}
                            </p>
                          )}
                          <p className="amount-label">Payout</p>
                        </div>
                      </div>
                    </div>
                  )
                }

              </div>
            </div>
          )
        }

        {
          jobTooltipVisible && currentJobs.length === 1 && property && (
            <div className="tooltip" style={{ top: jobTooltipPosition.top, left: jobTooltipPosition.left }} onClick={() => setJobTooltipVisible(false)}>
              <div className="tooltip-content">
                <div className="reservation-tooltip-container">
                  <div className="reservation-tooltip-info">
                    <div className="reservation-icon"><img src={getJobLightIcon(currentJobs[0].jobType)} alt={jobList[0].jobType} /></div>
                    <div className="reservation-value">{currentJobs[0].jobType}</div>
                  </div>
                  <div className="reservation-tooltip-info">
                    {currentJobs[0].status === 'finished' && (
                      <div className="confirmed-icon">
                        <img src={checkCircle} alt="Check circle icon" />
                      </div>
                    )}
                    <div className={`reservation-value ${currentJobs[0].status === 'Open' ? 'not-confirmed' :
                      currentJobs[0].status === 'Pending' ? 'ongoing' :
                        'confirmed'
                      }`}>
                      {currentJobs[0].status === 'Open' ? 'Open' :
                        currentJobs[0].status === 'Pending' ? 'Pending' :
                          'Completed'}
                    </div>
                  </div>
                </div>
                <div className="reservation-info">
                  <div className='reservation-container'>
                    <div className="reservation-dates">
                      <p>{moment(currentJobs[0].startDate).format('MMM D, YYYY')}</p>
                      <div className="time-info">
                        <span className='mr-3'><img src={clock} alt="Clock Icon" />{moment(currentJobs[0].startDate).utc().format('h:mm A')} <span className='mx-1'>
                          - </span>
                          <img src={clock} alt="Clock Icon" /> {moment(currentJobs[0].endDate).utc().format('h:mm A')}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='property-dark-row'>
                  <div className="property-dark">
                    <div className={property.image ? "listing-image" : "listing-image-none"}>
                      <img src={property.image || homeDark} alt={property.name || 'Property'} />
                    </div>
                    <div>
                      <h3>{property.name}</h3>
                      <p>{property.address}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }

        {
          jobTooltipVisible && currentJobs.length > 1 && property && (
            <div className="tooltip-more" style={{ top: jobTooltipPosition.top, left: jobTooltipPosition.left }} onClick={() => setJobTooltipVisible(false)}>
              <div className="tooltip-content">
                {currentJobs.map((job, index) => (
                  <div key={index} className="reservation-tooltip-container">
                    <div className="reservation-tooltip-info">
                      <div className="reservation-icon"><img src={getJobLightIcon(job.jobType)} alt={job.jobType} /></div>
                      <div className="reservation-info-tooltip">
                        {job.status === 'finished' && (
                          <div className="confirmed-icon">
                            <img src={checkCircle} alt="Check Icon" />
                          </div>
                        )}
                        <div className={`reservation-value ${job.status === 'Open' ? 'not-confirmed' :
                          job.status === 'Pending' ? 'ongoing' :
                            'confirmed'
                          }`}>
                          {job.status === 'Open' ? 'Open' :
                            job.status === 'Pending' ? 'Pending' :
                              'Completed'}
                        </div>
                        <div className="reservation-value-small">{job.jobType}</div>
                        {/* <div className="reservation-value-more-small">Contractor's name</div> */}
                      </div>
                    </div>
                  </div>
                ))}
                <div className="reservation-info">
                  <div className='reservation-container'>
                    <div className="reservation-dates">
                      <p>{moment(currentJobs[0].startDate).format('MMM D, YYYY')}</p>
                      <div className="time-info">
                        <span className='mr-3'><img src={clock} alt="Clock Icon" /> {moment(currentJobs[0].startDate).utc().format('h:mm A')} <span className='mx-1'>
                          - </span>
                          <img src={clock} alt="Clock Icon" /> {moment(currentJobs[0].endDate).utc().format('h:mm A')}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='property-dark-row'>
                  <div className="property-dark">
                    <div className={property.image ? "listing-image" : "listing-image-none"}>
                      <img src={property.image || homeDark} alt={property.name || 'Property'} />
                    </div>
                    <div>
                      <h3>{property.name}</h3>
                      <p>{property.address}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }

        {newAddedReservationTooltipVisible && newlyAddedReservationId && (
          <div className="hover-tooltip">
            <p>New Reservation</p>
          </div>
        )}

        {newAddedJobTooltipVisible && (
          <div className="hover-tooltip">
            <p>New Job</p>
          </div>
        )}

      </div>
    </td>
  );
};

export default React.memo(DayCell);