import moment from 'moment';

export const generateDatesFromSunday = (days: number): string[] => {
  const dates = [];
  const now = moment();
  const currentDayOfWeek = now.day();
  const startOfWeek = now.clone().subtract(currentDayOfWeek, 'days');
  for (let i = 0; i < days; i++) {
    dates.push(startOfWeek.clone().add(i, 'days').format('YYYY-MM-DD'));
  }

  return dates;
};

export const generateDatesForMonth = (year: number, month: number): string[] => {
  const dates = [];
  const startOfMonth = moment({ year, month }).startOf('month');
  const endOfMonth = startOfMonth.clone().endOf('month');
  const daysInMonth = endOfMonth.date();

  for (let i = 0; i < daysInMonth; i++) {
    dates.push(startOfMonth.clone().add(i, 'days').format('YYYY-MM-DD'));
  }

  // Ensure the array has 31 days by adding days from the next month if necessary
  if (daysInMonth < 31) {
    const daysToAdd = 31 - daysInMonth;
    for (let i = 0; i < daysToAdd; i++) {
      dates.push(endOfMonth.clone().add(i + 1, 'days').format('YYYY-MM-DD'));
    }
  }

  return dates;
};

export const getPageNumber = (index: number): number => {
  const pageSize = 10;
  return Math.floor(index / pageSize) + 1;
  // const pageSize = 10;
  // return Math.floor(index + 1);
};

export const isEmailValid = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const isPhoneNumberValid = (phone: string) => {
  const phoneRegex = /^(\+?[1-9]\d{8,9}|0\d{8,9})$/;
  return phoneRegex.test(phone);
};

export const isDateValid = (date: string) => {
  return !isNaN(Date.parse(date));
};

export const daysOfWeek = [
  { full: 'SUNDAY', short: 'Su' },
  { full: 'MONDAY', short: 'Mo' },
  { full: 'TUESDAY', short: 'Tu' },
  { full: 'WEDNESDAY', short: 'We' },
  { full: 'THURSDAY', short: 'Th' },
  { full: 'FRIDAY', short: 'Fr' },
  { full: 'SATURDAY', short: 'Sa' },
];

