import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

interface ScriptObject {
  elementId: string;
  type: string;
  appId: string;
  propertyId: string;
  token: string;
  channelCode: string;
  language: string;
  themeColor?: {
    iconColor?: string;
  };
}

declare global {
  interface Window {
    loadScript: (config: ScriptObject) => void;
  }
}

const Widget: React.FC = () => {
  const [searchParams] = useSearchParams();
  
  useEffect(() => {
    // Load external script
    const script = document.createElement('script');
    script.src = "https://static.otaswitch.com/JS/script.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      const tmpScriptObj: ScriptObject = {
        elementId: "app",
        type: "channel-Mapping",
        appId: "dGhlYm5iZHJlYW0uc3Vpc3N1LmNvbQ==",
        propertyId: searchParams.get('pmsId') || "",
        token: searchParams.get('token') || "",
        channelCode: searchParams.get('channelCode') || "",
        language: "en",
        themeColor: {
          iconColor: "#1e8ce4"
        }
      };

      window.loadScript(tmpScriptObj);
    };

    // Cleanup
    return () => {
      document.body.removeChild(script);
    };
  }, [searchParams]);

  return (
    <div id="app"></div>
  );
};

export default Widget;